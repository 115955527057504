import { ActionType } from "../action-types"
import { Actions } from "../actions"

interface rejectAssetState {
    loading: boolean
    payload?: any
}

export const defaultRejectAssetState: rejectAssetState = {
    loading: false
}

const rejectAssetReducer = (state: rejectAssetState = defaultRejectAssetState, action: Actions): rejectAssetState => {
    switch (action.type) {
        case ActionType.REJECT_ASSET_REQUEST:
            return {
                loading: true
            }
        case ActionType.REJECT_ASSET_FAILURE:
            return {
                loading: false,
                payload: action.error
            }
        case ActionType.REJECT_ASSET_SUCCESS:
            return {
                loading: false,
                payload: action.payload
            }
        case ActionType.CLEAR_ALL:
            return {
                loading: false
            }
        case ActionType.CLEAR_ALL_ACTION_PAYLOADS:
                    return {
                        loading: false,
                        payload: undefined
                    }
        default:
            return state
    }
}

export default rejectAssetReducer