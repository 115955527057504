export default function HomeRoute(): JSX.Element {
    return (<>
    <div className="w-full pb-20">
        <div className="font-jost font-normal uppercase grid">
            <div className="w-full justify-center text-center">
                <div className="md:text-6xl text-4xl text-white">ADMIN PLATFORM</div>
            </div>
        </div>
    </div>
    </>)
}
