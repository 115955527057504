import { createContext, useContext } from 'react'
//import { v4 as uuidv4 } from 'uuid';

export interface AdminAccount {
  email               : string,
  password            : string,
  password_salt?      : string,
  account_permissions?: string[],
  logged_in?          : boolean,
}

export interface strLoginInfo {
  username     : string,
  password     : string
}

export const defaultAdminState: AdminAccount = {
  email        : '',
  password     : '',
  logged_in    : false,
  account_permissions    : [],
}


export const loginInfo = {
  username: "",
  password: ""
}

export const AdminContext = createContext<AdminAccount>(defaultAdminState)
export const useAdminContext = () => useContext(AdminContext)
