export enum ActionType {

    SIGNIN_ADMIN_ACCOUNT_REQUEST = "SIGNIN_ADMIN_ACCOUNT_REQUEST",
    SIGNIN_ADMIN_ACCOUNT_SUCCESS = "SIGNIN_ADMIN_ACCOUNT_SUCCESS",
    SIGNIN_ADMIN_ACCOUNT_FAILURE = "SIGNIN_ADMIN_ACCOUNT_FAILURE",

    GET_ADMIN_ACCOUNT_PROFILE_REQUEST = "GET_ADMIN_ACCOUNT_PROFILE_REQUEST",
    GET_ADMIN_ACCOUNT_PROFILE_SUCCESS = "GET_ADMIN_ACCOUNT_PROFILE_SUCCESS",
    GET_ADMIN_ACCOUNT_PROFILE_FAILURE = "GET_ADMIN_ACCOUNT_PROFILE_FAILURE",

    GET_ALL_SUBMITTED_ASSETS_REQUEST = "GET_ALL_SUBMITTED_ASSETS_REQUEST",
    GET_ALL_SUBMITTED_ASSETS_SUCCESS = "GET_ALL_SUBMITTED_ASSETS_SUCCESS",
    GET_ALL_SUBMITTED_ASSETS_FAILURE = "GET_ALL_SUBMITTED_ASSETS_FAILURE",

    GET_ALL_SUBMITTED_TOKENS_REQUEST = "GET_ALL_SUBMITTED_TOKENS_REQUEST",
    GET_ALL_SUBMITTED_TOKENS_SUCCESS = "GET_ALL_SUBMITTED_TOKENS_SUCCESS",
    GET_ALL_SUBMITTED_TOKENS_FAILURE = "GET_ALL_SUBMITTED_TOKENS_FAILURE",

    GET_ASSET_INFO_REQUEST = "GET_ASSET_INFO_REQUEST",
    GET_ASSET_INFO_SUCCESS = "GET_ASSET_INFO_SUCCESS",
    GET_ASSET_INFO_FAILURE = "GET_ASSET_INFO_FAILURE",

    GET_TOKEN_INFO_REQUEST = "GET_TOKEN_INFO_REQUEST",
    GET_TOKEN_INFO_SUCCESS = "GET_TOKEN_INFO_SUCCESS",
    GET_TOKEN_INFO_FAILURE = "GET_TOKEN_INFO_FAILURE",

    APPROVE_ASSET_REQUEST = "APPROVE_ASSET_REQUEST",
    APPROVE_ASSET_SUCCESS = "APPROVE_ASSET_SUCCESS",
    APPROVE_ASSET_FAILURE = "APPROVE_ASSET_FAILURE",

    DENY_ASSET_REQUEST = "DENY_ASSET_REQUEST",
    DENY_ASSET_SUCCESS = "DENY_ASSET_SUCCESS",
    DENY_ASSET_FAILURE = "DENY_ASSET_FAILURE",

    REJECT_ASSET_REQUEST = "REJECT_ASSET_REQUEST",
    REJECT_ASSET_SUCCESS = "REJECT_ASSET_SUCCESS",
    REJECT_ASSET_FAILURE = "REJECT_ASSET_FAILURE",

    APPROVE_TOKEN_REQUEST = "APPROVE_TOKEN_REQUEST",
    APPROVE_TOKEN_SUCCESS = "APPROVE_TOKEN_SUCCESS",
    APPROVE_TOKEN_FAILURE = "APPROVE_TOKEN_FAILURE",

    DENY_TOKEN_REQUEST = "DENY_TOKEN_REQUEST",
    DENY_TOKEN_SUCCESS = "DENY_TOKEN_SUCCESS",
    DENY_TOKEN_FAILURE = "DENY_TOKEN_FAILURE",

    REJECT_TOKEN_REQUEST = "REJECT_TOKEN_REQUEST",
    REJECT_TOKEN_SUCCESS = "REJECT_TOKEN_SUCCESS",
    REJECT_TOKEN_FAILURE = "REJECT_TOKEN_FAILURE",

    CLEAR_ALL = "CLEAR_ALL",

    CLEAR_ALL_ACTION_PAYLOADS = "CLEAR_ALL_ACTION_PAYLOADS",
    GET_FILE_REQUEST = "GET_FILE_REQUEST",
    GET_FILE_SUCCESS = "GET_FILE_SUCCESS",
    GET_FILE_FAILURE = "GET_FILE_FAILURE",
    GET_DOCUMENTS_REQUEST = "GET_DOCUMENTS_REQUEST",
    GET_DOCUMENTS_SUCCESS = "GET_DOCUMENTS_SUCCESS",
    GET_DOCUMENTS_FAILURE = "GET_DOCUMENTS_FAILURE",
    GET_IMAGES_REQUEST = "GET_IMAGES_REQUEST",
    GET_IMAGES_SUCCESS = "GET_IMAGES_SUCCESS",
    GET_IMAGES_FAILURE = "GET_IMAGES_FAILURE",
    GET_VIDEOS_REQUEST = "GET_VIDEOS_REQUEST",
    GET_VIDEOS_SUCCESS = "GET_VIDEOS_SUCCESS",
    GET_VIDEOS_FAILURE = "GET_VIDEOS_FAILURE"
}