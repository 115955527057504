import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { bindActionCreators } from "redux";
import { Asset, defaultAsset } from "../AssetContext";
import { actionCreators, State } from "../state";
import LabelAndInput from "../components/LabelAndInput";
import { LightButton } from "../components/LightButton";
import LightTable from "../components/LightTable";
import ArtTokenBody from "../components/tokenBody/ArtTokenBody";
import AifTokenBody from "../components/tokenBody/AifTokenBody";
import HorseTokenBody from "../components/tokenBody/HorseTokenBody";
import { MediaFile } from "../AssetContext";
import { useHistory } from 'react-router-dom'


export default function AssetDetailRoute(props: any): JSX.Element {
    const uuid = props.match.params.uuid
    const dispatch = useDispatch()
    const actions = bindActionCreators(actionCreators, dispatch)

    const [asset, setAsset] = useState<{Asset: Asset, AssetMedia: any[]}>({Asset: defaultAsset, AssetMedia: []})
    const [media, setMedia] = useState<MediaFile[]>([])
    const [comment, setComment] = useState("")
    const [response, setResponse] = useState(0)
    let history = useHistory()

    useEffect(() => {
        actions.getAssetInfo(uuid, setAsset)
    }, [])

    if(asset && (!asset.Asset.uuid || asset.Asset.uuid=== "0")) {
        <div className="grid justify-center text-white text-4xl font-urbanist">Asset not found</div>
    }
    
    var tokenBody: JSX.Element = <></>
    switch(asset.Asset.asset_category) {
        case "ART":
            tokenBody = <ArtTokenBody assetInfo={asset.Asset} media={asset.AssetMedia} tokenSymbol={"AAA"}></ArtTokenBody>
            break
        case "AIF":
            tokenBody = <AifTokenBody assetInfo={asset.Asset}></AifTokenBody>
            break
        case "SPORTEX":
            tokenBody = <HorseTokenBody assetInfo={asset.Asset} media={asset.AssetMedia}></HorseTokenBody>
            break
        default:
            tokenBody = <></>

    }

    async function handleAcceptAsset(){
        actions.approveAsset(uuid, setResponse)
        history.push("/list")
    }

    function handleDenyAsset(){
        if(comment === ""){
            alert('Please explain why you are denying this asset')
        } else {
            actions.denyAsset({'uuid':uuid, 'comment': comment})
        }
    }

    function handleRejectAsset(){
        if(comment === ""){
            alert('Please explain why you are rejecting this asset')
        } else {
            actions.rejectAsset({'uuid':uuid, 'comment': comment})
        }
    }

    return (
        <>
            <div className="grid bg-background px-10 py-10">

                <div className="grid pt-5 pb-10 items-center pl-2 pr-2 lg:mx-32">

                    <div className="font-urbanist text-4xl text-blue float-left uppercase tracking-wider">
                        Asset to approve:
                    </div>
                    <div className="mt-10">
                        <LightTable headers={["CAT", "TYPE", "Name", "Issuer", "Creation date"]}
                            rows={[[asset.Asset.asset_category, asset.Asset.asset_type, asset.Asset.name, asset.Asset.issuer_name,
                                asset.Asset.date_created.substring(0, 10)]]} />
                    </div>
                    <div className="grid md:grid-cols-3 mt-10">
                        <LightButton label={"Approve"} className="rounded-xl font-bold px-2 text-xl mx-10 md:my-0 my-2"
                            onClick={handleAcceptAsset} />
                        <LightButton label={"Deny"} className="rounded-xl font-bold px-2 text-xl mx-10 md:my-0 my-2"
                            onClick={handleDenyAsset} />
                        <LightButton label={"Reject"} className="rounded-xl font-bold px-2 text-xl mx-10 md:my-0 my-2"
                            onClick={handleRejectAsset} />
                    </div>
                    <div className="w-full py-5">
                        <div className="grid md:grid-cols-3">
                            <LabelAndInput label={"If not approved explain the reason"} value={comment} fun={setComment} 
                                type="textarea" rows={3} className="md:col-span-2" required={true} color="blue"/>
                        </div>
                    </div>
                    {tokenBody}
                </div>
                
            </div>
            
        </>)
}