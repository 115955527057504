import  arrowsImg  from "../media/arrows.png"

export default function LightTable(props: {headers: ({name: string, sort: any} | string)[], rows: any[][]}) {

    return <>
        <table className={"uppercase w-full text-blue bg-background text-center border-collapse "}>
            <thead>
                <tr className="leading-7 font-urbanist font-bold">
                    {props.headers.map((header, headerIndex) => 
                        { return <th 
                                className={ typeof header === "string" ? "" : "cursor-pointer"}
                                onClick={typeof header === "string" ? undefined :  () => header.sort()}
                                key={headerIndex}>
                                    {typeof header === "string" ? 
                                        <div className="flex justify-items-center justify-center items-center ">
                                            {header}
                                        </div> : 
                                        <div className="flex justify-items-center justify-center items-center hover:bg-primary hover:text-background">
                                            {header.name}
                                            <img src={arrowsImg} className=" ml-1"/>

                                        </div>}
                            </th>})}
                </tr>
            </thead>
            <tbody>
                {props.rows.map((row, rowIndex) => { 
                    return <tr className={" hover:bg-background_dk font-code-pro font-medium"} 
                        key={rowIndex}>
                        {row.map((cell, colIndex) => { 
                            return <td key={colIndex} className={"border-l-0 border-t-0 text-xl border-r-0 border-b-1 "+
                                " border-solid border-wild_blue_yonder leading-10 "+
                                    (colIndex%2===1 ? "text-wild_blue_yonder" : "text-blue") }> 
                                {cell}
                            </td>}
                        )}
                    </tr>}               
                )}
            </tbody>
        </table>
    </>
}