export default function LabelAndDescription(props: {label: string, description: string, className?: string}) : JSX.Element {

    return props.description ?
        <div className={props.className}>
            
            <div className="uppercase font-code-pro text-blue font-bold text-lg leading-7 tracking-wider">
                {props.label}
            </div>
            <div className="uppercase font-code-pro text-blue text-lg leading-7 tracking-wider">
                {props.description}
            </div>
        </div>
        : <></>
}