import { Link } from 'react-router-dom'
import LightTable from "./LightTable";

export default function AssetDescriptionTable(props: {assetsList: {assets: any[]}, assetLinks?: boolean}): JSX.Element {

    const headers = ["CAT", "TYPE", "Name", "Issuer"]

    function assetsList2list(l: any[]) {
        if(l) {
            var tmp = l.map((a) => [a.asset_category, a.asset_type, a.name, a.issuer_name])
            return props.assetLinks ? tmp.map((a, i) => a.map((b) => <Link to={"/asset/"+l[i].uuid}>{b}</Link>)) : tmp.map((a, i) => a.map((b) => b))
        }
        return []
    }

    return <>
        <div className="py-1">
            <LightTable headers={headers} rows={assetsList2list(props.assetsList.assets)} ></LightTable>
        </div>
    </>
}