import React, { Component } from 'react'
import PropTypes from "prop-types"
import { Disclosure  } from '@headlessui/react'
import { Link, withRouter } from 'react-router-dom'
import { AdminContext } from '../AdminContext'
import image from '../media/Logo-dexx.png'
import imageSignOut from '../media/sign-out.png'
import NavbarItem from './NavbarItem'
import DropDownMenu from './DropDownMenu'
import { LightButton } from './LightButton'

//const classNames = (...classes: string[]) => classes.filter(Boolean).join(' ')

class Navbar extends Component<any, any> {

    static contextType = AdminContext
    static propTypes = { location: PropTypes.object.isRequired }
    

    render() {

        const admin: any = this.context
        const { handleSignOutAdmin } = this.props; 

        let profileUrl: string = ""
        if(admin.email === undefined || admin.email === "") profileUrl = "/account/signin"
        else profileUrl = "/profile"

        return (
            <Disclosure as="nav">
                {({ open }) => (
                    <>
                        <div className="flex justify-between text-xl">
                            
                            <a href="/">
                                <img className="float-left h-16 md:h-24 xl:h-28" src={image} alt="dexx"/>
                            </a>
                            <div className="hidden lg:block">
                                {admin.email !== '' ? 
                                    <>
                                        <a href="#" className="float-right my-7" onClick={handleSignOutAdmin}>
                                            <img className="border-solid border-white border-3 hover:border-4 rounded-full h-14 w-14 mx-5" 
                                                src={imageSignOut}/>
                                        </a>
                                        <NavbarItem id="user" to={profileUrl} label={admin.email}  border="left" className="my-6 font-medium"/>
                                        {
                                            this.props.showAssets ? <>
                                                <NavbarItem id="a-t" to="/list" label="approve"  border="left" className="my-6 font-medium"/>
                                                <NavbarItem id="a-t" to="/users-bank" label="bank"  border="left" className="my-6 font-medium"/>
                                                <NavbarItem id="a-t" to="/users" label="users"  border="" className="my-6 font-medium"/>
                                                </> : <></>
                                        }
                                        {/*<NavbarItem id="menu" to="/menu" label="menu"  border="" className="my-6 font-medium"/> */}
                                    </> : 
                                    <>  
                                        <Link to="/account/signin">
                                            <LightButton label="Log in" alternate={true} 
                                                className="tracking-widest px-10 rounded-md mt-8 mx-2 float-right"
                                                fontClasses='text-xl font-medium' />
                                        </Link>
                                    </>}
                            </div>
                            <DropDownMenu username={admin.email} logged={admin.email !== ''} signOut={handleSignOutAdmin} />
                        </div>
                    </>
                )}
            </Disclosure>
        )
    }
}
export default withRouter(Navbar)
