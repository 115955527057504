import { ActionType } from '../action-types'
import { Actions } from '../actions'

interface loginState {
    loading: boolean
    loggedIn: boolean
    loggingOut : boolean
    payload?: any[]
}

export const defaultLoginState: loginState = {
    loading: false,
    loggedIn: false,
    loggingOut: false,
    payload: []
}

const loginReducer = (state: loginState = defaultLoginState, action: Actions): loginState => {
    switch(action.type) {

        case ActionType.SIGNIN_ADMIN_ACCOUNT_REQUEST:
            return {
                loading: true,
                loggedIn: false,
                loggingOut: false
            }
        case ActionType.SIGNIN_ADMIN_ACCOUNT_FAILURE:
            return {
                loading: false,
                loggedIn: false,
                loggingOut: false,
                payload: action.error
            }
        case ActionType.SIGNIN_ADMIN_ACCOUNT_SUCCESS:
            return {
                loading: false,
                loggedIn: true,
                loggingOut: false,
                payload: action.payload
            }
        case ActionType.CLEAR_ALL:
            return {
                loading: false,
                loggedIn: false,
                loggingOut: true,
                payload: undefined
            }
        default:
            return state
    }
}

export default loginReducer