import { ActionType } from "../action-types"
import { Actions } from "../actions"

interface rejectTokenState {
    loading: boolean
    payload?: any
}

export const defaultRejectTokenState: rejectTokenState = {
    loading: false
}

const rejectTokenReducer = (state: rejectTokenState = defaultRejectTokenState, action: Actions): rejectTokenState => {
    switch (action.type) {
        case ActionType.REJECT_TOKEN_REQUEST:
            return {
                loading: true
            }
        case ActionType.REJECT_TOKEN_FAILURE:
            return {
                loading: false,
                payload: action.error
            }
        case ActionType.REJECT_TOKEN_SUCCESS:
            return {
                loading: false,
                payload: action.payload
            }
        case ActionType.CLEAR_ALL:
            return {
                loading: false
            }
        case ActionType.CLEAR_ALL_ACTION_PAYLOADS:
                return {
                    loading: false,
                    payload: undefined
                }
        default:
            return state
    }
}

export default rejectTokenReducer