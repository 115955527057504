export function LightButton(props: {label: String, onClick?: any, alternate?: boolean, className?: string, fontClasses?: string}): JSX.Element {
    var isAlternate = false
    if(props.alternate)
        isAlternate = props.alternate

    return <>
        <div className={"font-urbanist border border-solid"+
            " not-italic text-center uppercase  cursor-pointer "+
            " tracking-wider py-2 w-fit "+
            (props.fontClasses ? props.fontClasses : "  text-xl ")+
            (isAlternate ? " text-white border-white hover:bg-background hover:text-blue hover:border-blue " : 
                " border-blue text-blue hover:bg-primary hover:text-white ")+
            (props.className ? props.className : "")}
            onClick={props.onClick}>{props.label}</div>
    </>
}