import IconButton from "@mui/material/IconButton";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { HtmlTooltip } from "./HtmlTooltip";
import { TextField } from "@mui/material";

export default function LabelAndInput(props: {label: string, value: any, fun: any,
        type: string, className?: string, passwordTip?: any, step?: any, required?: boolean,
        rows?: number, options?: {value: any, text: string}[], color?: string, size?: string}): JSX.Element {
    function addDatePadding(n: string){
        return n.length < 2 ? "0"+n : n
    }

    function dateIsValid(date: any) {
        return !Number.isNaN(new Date(date).getTime());
    }

    function datePH() {
        var d = new Date(props.value)
        return props.type==="date" && dateIsValid(d) ? d.toISOString().substr(0,10) : ""
    }

    const current = new Date()
    const month = addDatePadding((current.getMonth()+1).toString())
    const day = addDatePadding(current.getDate().toString())
    const date = (current.getFullYear()-18).toString()+"-"+month+"-"+day
    var color = " text-white "
    var textSize = " text-xl py-4" 
    
    if(props.color)
        switch(props.color) {
            case "blue":
                color = " text-blue border-blue "
                break
            case "light blue":
                color = " text-wild_blue_yonder border-wild_blue_yonder"
                break
            case "white":
            default:
                color = " text-white border-white "
        }
    if(props.size) {
        switch(props.size) {
            case "md":
                textSize = " text-md py-2 "
                break
            default:
                textSize = " text-xl py-4"
        }
    }
    const inputClasses = "w-full bg-transparent border-t-0 border-l-0 border-r-0 border-solid border-white border-b-1"+
                    " font-urbanist pl-2 leading-none"+color+textSize

    let inputEl: JSX.Element

    switch(props.type) {
        case "date":
            inputEl = props.required ?
                <input className={inputClasses}
                    defaultValue={datePH()} onChange={e => props.fun(e.target.value)} type={props.type} min="1900-01-01" max={date} required /> :
                <input className={inputClasses}
                    defaultValue={datePH()} onChange={e => props.fun(e.target.value)} type={props.type} min="1900-01-01" max={date} />
            break
        case "checkbox":

            inputEl = props.required ?
                <input className="w-20 py-4 h-5 border-solid border-white
                        text-white text-xl font-urbanist pl-2 leading-none"
                    value={props.value} onChange={e => props.fun(e.target.checked)} type={props.type} required/> :
                <div className={"w-5"+(props.value ? " bg-primary" : " ")}>
                    <div className={"w-8  h-8 border-solid"+
                        " text-xl font-urbanist pl-2 leading-none border-2 mt-6 "+color+(props.value ? " bg-primary" : " ")}
                        onClick={() => { props.fun(!props.value)}}>
                    </div></div>
            break
        case "number":
            inputEl = props.required ?
                <input className={inputClasses}
                    value={props.value} onChange={e => props.fun(e.target.value)} type="text" pattern="[0-9]*"
                    inputMode="numeric" step={props.step ? props.step : "any"} required/> :
                <input className={inputClasses}
                    value={props.value} onChange={e => props.fun(e.target.value)} type="text" pattern="[0-9]*"
                    inputMode="numeric" step={props.step ? props.step : "any"}/>
            break
        case "textarea":
            inputEl = props.required ?
                <textarea className={inputClasses}
                    value={props.value} onChange={e => props.fun(e.target.value)} rows={props.rows ? props.rows: 3} required/> :
                <textarea className={inputClasses}
                value={props.value} onChange={e => props.fun(e.target.value)} rows={props.rows ? props.rows: 3} required/>
            break
        case "select":
            inputEl = props.required ?
                <select value={props.value} onChange={e => props.fun(e.target.value)}
                    className={inputClasses} required>
                    {props.options ? props.options.map(({value: v, text: t}, i) => {return <option value={v} key={i} className="bg-primary">{t}</option>}) 
                    : <></>}
                </select>
                 : <select value={props.value} onChange={e => props.fun(e.target.value)}
                    className={inputClasses}>
                    {props.options ? props.options.map(({value: v, text: t}, i) => {return <option value={v} key={i} className="bg-primary">{t}</option>}) : <></>}
                </select>
            break
        case "no-input":
            inputEl = <div className={"w-full py-2 bg-transparent font-urbanist pl-2 leading-none "+color}>
                {props.value}
            </div>
            break
        case "mui-form":
            inputEl = <div className={"w-full bg-transparent font-urbanist leading-none "+color}>
                    <TextField 
                        autoFocus 
                        variant="standard" 
                        required 
                        onChange={e => props.fun(e.target.value)}
                        fullWidth
                        placeholder={props.value}
                        />
                </div>
            break
        default:
            inputEl = props.required ?
                <input className={inputClasses}
                    value={props.value} onChange={e => props.fun(e.target.value)} type={props.type} required/> :
                <input className={inputClasses}
                value={props.value} onChange={e => props.fun(e.target.value)} type={props.type} />
    }
    
    return <>
        <div className={props.className+" font-urbanist py-2 tracking-wider "}>
            <label className={"w-full uppercase py-4"+ color+textSize}>{props.label}</label>
            {props.passwordTip ? <>
                <HtmlTooltip
                            placement="top-start"
                            title={
                                <div className="p-5 font-urbanist font-semibold text-blue text-base">
                                    <div className=" font-bold text-blue text-lg">Password must meet complexity requirements:</div>
                                    <ul>
                                    <li>Not contain the users account name.</li>
                                    <li>Exceeded eight characters in length.</li>
                                    <li>Contain at least one character from at least three of four sets of characters:</li>
                                    <li className="ml-3"><em>A through Z.</em></li>
                                    <li className="ml-3"><em>a through z.</em></li>
                                    <li className="ml-3"><em>0 through 9.</em></li>
                                    <li className="ml-3"><em>Symbols such !@#$%^&*"</em></li>
                                    </ul>
                                </div>
                            }
                        >
                            <IconButton>
                                <InfoOutlinedIcon className="mb-2" sx={{ color: 'white' }} />
                            </IconButton>
                    </HtmlTooltip>
            </> : <></>}
            {inputEl}
        </div>
    </>
}