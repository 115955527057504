import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import image from "../media/arrow.png"
import { actionCreators, State } from '../state'
//import Cookies from 'js-cookie'
//import { AdminAccount, defaultAdminState } from '../AdminContext'
//import { useHistory  } from 'react-router-dom'
import LabelAndInput from "../components/LabelAndInput";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";


export default function SignInRoute(): JSX.Element {
    const dispatch = useDispatch()
    const actions = bindActionCreators(actionCreators, dispatch)
    const adminLoggingIn = useSelector((state: State) => state.loginAdmin)
    const [buttonAnimation, setButtonAnimation] = useState(false)

    let history = useHistory();

    //Email
    const[username, setUsername] = useState<string>("")
    //Password
    const[password, setPassword] = useState<string>("")

    function handleSubmitSignInInvestor(e: React.MouseEvent) {
        const accessingUser = {
            username: username,
            password: password
        }
        actions.SignInAdminAccount(accessingUser)
    }
    useEffect(() => {
        if(adminLoggingIn.loggedIn) {
            history.push("/")
        }
    }, [adminLoggingIn])

    return (
        <>  
            <div className="grid lg:grid-cols-6 md:grid-cols-4">
                <div className="lg:col-span-2"></div>
                <div className="col-span-2">
                    <LabelAndInput label="Email" value={username} fun={setUsername} type="text"/>
                </div>
                <div></div>
                <div className="lg:col-span-2"></div>
                <div className="col-span-2">
                    <LabelAndInput label={"Password"} value={password} fun={setPassword} type={"password"} />
                </div>
                <div className="lg:col-span-2"></div>
                <div className="lg:col-span-3 col-span-2"></div>
                <div className="grid justify-end mt-10 cursor-pointer">
                    <Link onClick={handleSubmitSignInInvestor} to="/account/signin">
                        <img className={"img-button "+(buttonAnimation ? "animate-wiggle" : "")} src={image} alt="->" 
                            onMouseEnter={() => {setButtonAnimation(true)}}
                            onMouseLeave={() => {setButtonAnimation(false)}}></img>
                    </Link>
                </div>
            </div>
        </>)    
}