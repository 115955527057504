import { useState } from "react";
import { HeaderButton } from "./HeaderButton";

export default function TabsMenu(props: {headers: string[], contents: JSX.Element[], classNameHds?: string, 
        classNameHdsSelected?: string, classNameHdsNonSelected?: string}) : JSX.Element {
    const [toBeShown, setToBeShown] = useState(props.headers.map((_,i) => i===0 ? true : false))
    
    function handleClick(i: number) {
        var tmp = props.headers.map((_,i) => false)
        tmp[i] = true
        setToBeShown(tmp)
    }
    
    return <>
        <div className={"grid grid-cols-"+props.headers.length+" menu-head mt-5"}>
            {props.headers.map((e, i) => <HeaderButton name={e} click={() => handleClick(i)} 
                className={(props.classNameHds ? props.classNameHds : "uppercase cursor-pointer py-5 px-1 text-center "+
                        "border-solid border-1 border-transparent mx-0.5 text-sm")+
                    (toBeShown[i] ? (props.classNameHdsSelected ? " "+props.classNameHdsSelected : " bg-primary text-background rounded-xl ") : 
                        (props.classNameHdsNonSelected ? " "+props.classNameHdsNonSelected : 
                            " text-gray hover:bg-primary hover:text-background rounded-xl"))} 
                id={i} key={i}/> )}
        </div>
        {props.contents.map((c, i) => toBeShown[i] ? 
            <div key={i}>{c}</div> : 
            <div key={i}></div>)}
    </>
}