import { Link } from "react-router-dom";

export default function NavbarItem(props: {label: string, border: string, id: string, to: string, className?: string, onClick?: any}) {
    return <>
        <Link key={props.id} to={props.to} onClick={props.onClick}
            className={"font-urbanist uppercase text-white py-5 px-10 hover:text-blue hover:bg-white "+
                "float-right border-t-0 border-b-0 "+
                (props.border === "right" ? "border-white border-r-3 md:border-solid  border-l-0 " 
                    : (props.border === "left" ? "border-white md:border-l-3 md:border-solid  border-r-0 " : " "))+props.className} >
                    {props.label}
        </Link>
    </>
}