import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Popup(props: {show: boolean, title: string, msg: string, btnOk: boolean, close: any, body?: JSX.Element}) : JSX.Element {
//  const [open, setOpen] = React.useState(false);
//  const handleClickOpen = () => { setOpen(true); };
//  const handleClose = () => { setOpen(false); };

  return (
    <>
      {/*<Button variant="outlined" onClick={handleClickOpen}>
        Slide in alert dialog
      </Button>*/}
      <Dialog
        open={props.show}
        TransitionComponent={Transition}
        keepMounted
        onClose={props.close}
        aria-describedby="alert-dialog-slide-description"
      >
        <div className="font-urbanist text-2xl uppercase p-4 font-blue bg-background text-blue">{props.title}</div>
        {props.msg ? <DialogContent className="bg-background">
          <div className="font-urbanist text-xl text-blue">
            {props.msg}
          </div> 
        </DialogContent> : <></>}
        <div className="bg-background">
          {props.body ? <DialogContent>{props.body}</DialogContent> : <></>}
        </div>
        
        {props.btnOk ? <DialogActions className="bg-background">
          {/*<Button onClick={props.close}>Disagree</Button>*/}
          <Button onClick={props.close}>OK</Button>
        </DialogActions> : <></>}
      </Dialog>
    </>
  );
}