import { ActionType } from "../action-types"
import { Dispatch } from "redux"
import { Actions } from "../actions"
import { client } from "../../client"
import { strLoginInfo } from "../../AdminContext"
import { MediaFile } from "../../AssetContext"


//signin
export const SignInAdminAccount = (accessingUser: strLoginInfo) => async (dispatch: Dispatch<Actions>) => {
    try {
        dispatch({type: ActionType.SIGNIN_ADMIN_ACCOUNT_REQUEST})
        let res = await client.SignInAdminAccount(accessingUser).then(result => {
            let data = {
                email : accessingUser.username,
                password: accessingUser.password,
                account_permissions: result[1].account_permissions
            }
            return [result[0], data]
        })
        dispatch({type: ActionType.SIGNIN_ADMIN_ACCOUNT_SUCCESS, payload: res})
    } catch (e) {
        dispatch( {type: ActionType.SIGNIN_ADMIN_ACCOUNT_FAILURE, error: e} )
    }
}

export const SignOutAdmin = () => async (dispatch: Dispatch<Actions>) => {
        dispatch({type: ActionType.CLEAR_ALL, payload: undefined})
}

export const getAllSubmittedAssets = (setFun: any) => async (dispatch: Dispatch<Actions>) => {
    try {
        dispatch({type: ActionType.GET_ALL_SUBMITTED_ASSETS_REQUEST})
        let res = await client.getAllSubmittedAssets().then(result => {
            return result[1]
        })
        setFun(await res.assets)
        dispatch({type: ActionType.GET_ALL_SUBMITTED_ASSETS_SUCCESS, payload: res})
    } catch (e){
        setFun({assets: []})
        dispatch( {type: ActionType.GET_ALL_SUBMITTED_ASSETS_FAILURE, error: e})
    }
}

export const getAllSubmittedTokens = (setFun: any) => async (dispatch: Dispatch<Actions>) => {
    try {
        dispatch({type: ActionType.GET_ALL_SUBMITTED_TOKENS_REQUEST})
        let res = await client.getAllSubmittedTokens().then(result => {
            return result[1]
        })
        setFun(await res.tokens)
        dispatch({type: ActionType.GET_ALL_SUBMITTED_TOKENS_SUCCESS, payload: res})
    } catch (e){
        setFun({tokens: []})
        dispatch( {type: ActionType.GET_ALL_SUBMITTED_TOKENS_FAILURE, error: e})
    }
}

export const getAssetInfo = (uuid: string, setFun: any) => async (dispatch: Dispatch<Actions>) => {
    try {
        dispatch({type: ActionType.GET_ASSET_INFO_REQUEST})
        let res = await client.getAssetInfo(uuid).then(result => {
            return result
        })
        if(res[0] === 200) setFun(await res[1])
        dispatch({type: ActionType.GET_ASSET_INFO_SUCCESS, payload: res})
    } catch (e){
        dispatch( {type: ActionType.GET_ASSET_INFO_FAILURE, error: e})
    }
}

export const getTokenDetail = (uuid: string) => async (dispatch: Dispatch<Actions>) => {
    try {
        dispatch({type: ActionType.GET_TOKEN_INFO_REQUEST})
        let res = await client.getTokenDetail(uuid).then(result => {
            return result[1]
        })
        dispatch({type: ActionType.GET_TOKEN_INFO_SUCCESS, payload: res})
    } catch (e){
        dispatch( {type: ActionType.GET_TOKEN_INFO_FAILURE, error: e})
    }
}

export const approveAsset = (uuid: string, setFun: any) => async (dispatch: Dispatch<Actions>) => {
    try {
        dispatch({type: ActionType.APPROVE_ASSET_REQUEST})
        let res = await client.approveAsset(uuid).then(result => {
            return result
        })
        setFun(res[0])
        dispatch({type: ActionType.APPROVE_ASSET_SUCCESS, payload: res})
    } catch (e){
        dispatch( {type: ActionType.APPROVE_ASSET_FAILURE, error: e})
    }
}

export const denyAsset = (info: any) => async (dispatch: Dispatch<Actions>) => {
    try {
        dispatch({type: ActionType.DENY_ASSET_REQUEST})
        let res = await client.denyAsset(info).then(result => {
            return result
        })
        dispatch({type: ActionType.DENY_ASSET_SUCCESS, payload: res})
    } catch (e){
        dispatch( {type: ActionType.DENY_ASSET_FAILURE, error: e})
    }
}

export const rejectAsset = (info: any) => async (dispatch: Dispatch<Actions>) => {
    try {
        dispatch({type: ActionType.REJECT_ASSET_REQUEST})
        let res = await client.rejectAsset(info).then(result => {
            return result
        })
        dispatch({type: ActionType.REJECT_ASSET_SUCCESS, payload: res})
    } catch (e){
        dispatch( {type: ActionType.REJECT_ASSET_FAILURE, error: e})
    }
}

export const approveToken = (uuid: string) => async (dispatch: Dispatch<Actions>) => {
    try {
        dispatch({type: ActionType.APPROVE_TOKEN_REQUEST})
        let res = await client.approveToken(uuid).then(result => {
            return result
        })
        dispatch({type: ActionType.APPROVE_TOKEN_SUCCESS, payload: res})
    } catch (e){
        dispatch( {type: ActionType.APPROVE_TOKEN_FAILURE, error: e})
    }
}

export const denyToken = (info: any) => async (dispatch: Dispatch<Actions>) => {
    try {
        dispatch({type: ActionType.DENY_TOKEN_REQUEST})
        let res = await client.denyToken(info).then(result => {
            return result
        })
        dispatch({type: ActionType.DENY_TOKEN_SUCCESS, payload: res})
    } catch (e){
        dispatch( {type: ActionType.DENY_TOKEN_FAILURE, error: e})
    }
}

export const rejectToken = (info: any) => async (dispatch: Dispatch<Actions>) => {
    try {
        dispatch({type: ActionType.REJECT_TOKEN_REQUEST})
        let res = await client.rejectToken(info).then(result => {
            return result[1]
        })
        dispatch({type: ActionType.REJECT_TOKEN_SUCCESS, payload: res})
    } catch (e){
        dispatch( {type: ActionType.REJECT_TOKEN_FAILURE, error: e})
    }
}

export const getMediaFile = (hashname: string) => async (dispatch: Dispatch<Actions>) => {
    try {
        dispatch({type: ActionType.GET_FILE_REQUEST})
        let res = await client.getMediaFile(hashname).then(result => {
            return result[1].url
        })
        dispatch({type: ActionType.GET_FILE_SUCCESS, payload: res})
    } catch (e){
        dispatch({type: ActionType.GET_FILE_FAILURE, error: e})
    }
}

export const getDocuments = (docsName: any, setFunction: any) => async (dispatch: Dispatch<Actions>) => {
    try {
        dispatch({type: ActionType.GET_DOCUMENTS_REQUEST})
        let tmp = docsName.filter((d: MediaFile) => 
            d.media_type_name === "Document")
        let res = tmp.map(async (d: MediaFile) => 
            await client.getMediaFile(d.filename).then(result => {return result[1].url}))
        setFunction(await Promise.all(res))
        dispatch({type: ActionType.GET_DOCUMENTS_SUCCESS, payload: await Promise.all(res)})
    } catch (e){
        dispatch({type: ActionType.GET_DOCUMENTS_FAILURE, error: e})
    }
}

export const getImages = (docsName: any, setFunction: any) => async (dispatch: Dispatch<Actions>) => {
    try {
        dispatch({type: ActionType.GET_IMAGES_REQUEST})
        let tmp = docsName.filter((d: MediaFile) => 
            d.media_type_name === "Image")
        let res = tmp.map(async (d: MediaFile) => 
            await client.getMediaFile(d.filename).then(result => {return result[1].url}))
        setFunction(await Promise.all(res))
        dispatch({type: ActionType.GET_IMAGES_SUCCESS, payload: await Promise.all(res)})
    } catch (e){
        dispatch({type: ActionType.GET_IMAGES_FAILURE, error: e})
    }
}

export const getVideos = (docsName: any, setFunction: any) => async (dispatch: Dispatch<Actions>) => {
    try {
        dispatch({type: ActionType.GET_VIDEOS_REQUEST})
        let tmp = docsName.filter((d: MediaFile) => 
            d.media_type_name === "Video")
        let res = tmp.map(async (d: MediaFile) => 
            await client.getMediaFile(d.filename).then(result => {return result[1].url}))
        setFunction(await Promise.all(res))
        dispatch({type: ActionType.GET_VIDEOS_SUCCESS, payload: await Promise.all(res)})
    } catch (e){
        dispatch({type: ActionType.GET_VIDEOS_FAILURE, error: e})
    }
}

export const getAllBankRequests = (setFunction: any) => async (dispatch: Dispatch<Actions>) => {
    try {
        let res = await client.getAllBankRequests().then(result => {
            return result
        })
        setFunction((await Promise.all(res))[1].transactions)
    } catch (e) {
        setFunction([])
    }
}

export const createDepositRequest = (depositRequest: any, setFunction?: any) => async (dispatch: Dispatch<Actions>) => {
    try {
        let res = await client.createDepositRequest(depositRequest).then(result => {
            return result
        })
        if (setFunction) setFunction((await Promise.all(res))[0])
    } catch (e) {
        setFunction(405)
    }
}

export const mint = (mintRequest: any, setFunction?: any) => async (dispatch: Dispatch<Actions>) => {
    try {
        let res = await client.mint(mintRequest).then(result => {
            return result
        })
        if (setFunction) setFunction((await Promise.all(res))[0])
    } catch (e) {
        setFunction(405)
    }
}

export const burn = (burnRequest: any, setFunction?: any) => async (dispatch: Dispatch<Actions>) => {
    try {
        let res = await client.burn(burnRequest).then(result => {
            return result
        })
        if (setFunction) setFunction((await Promise.all(res))[0])
    } catch (e) {
        setFunction(405)
    }
}

export const getUsersList = (setFunction?: any) => async (dispatch: Dispatch<Actions>) => {
    try {
        let res = await client.getUsersList().then(result => {
            return result
        })
        if (setFunction) setFunction((await Promise.all(res))[1].accounts)
    } catch (e) {
        setFunction([])
    }
}


export const setInvestorPermission = (accountUuid : string, setFunction?: any) => async (dispatch: Dispatch<Actions>) => {
    try {
        let res = await client.setInvestorPermission(accountUuid).then(result => {
            return result
        })
        if (setFunction) setFunction((await Promise.all(res))[0])
    } catch (e) {
        setFunction(405)
    }
}

export const setIssuerPermission = (accountUuid : string, setFunction?: any) => async (dispatch: Dispatch<Actions>) => {
    try {
        let res = await client.setIssuerPermission(accountUuid).then(result => {
            return result
        })
        if (setFunction) setFunction((await Promise.all(res))[0])
    } catch (e) {
        setFunction(405)
    }
}

export const setKYCPermission = (accountUuid : string, setFunction?: any) => async (dispatch: Dispatch<Actions>) => {
    try {
        let res = await client.setKYCPermission(accountUuid).then(result => {
            return result
        })
        if (setFunction) setFunction((await Promise.all(res))[0])
    } catch (e) {
        setFunction(405)
    }
}

// delete payloads for accepted/Denied/Rejected Assets/Tokens
export const resetAssetAndTokenPayloads = () => async (dispatch: Dispatch<Actions>) => {
    dispatch({type: ActionType.CLEAR_ALL_ACTION_PAYLOADS, payload: undefined})
}