//import { defaultSecurityToken, SecurityToken } from "../../PoolContext";
import { ActionType } from "../action-types";
import { Actions } from "../actions";

interface tokenDetailState {
    loading: boolean
    payload?: any
}

const defaultTokenDetailState: tokenDetailState = {
    loading: false,
    payload: ""
}

const tokenDetailReducer = (state: tokenDetailState = defaultTokenDetailState, action: Actions): tokenDetailState => {
    switch(action.type) {
        case ActionType.GET_TOKEN_INFO_REQUEST:
            return {
                loading: true,
            }
        case ActionType.GET_TOKEN_INFO_FAILURE:
            return {
                loading: false,
                payload: action.error
            }
        case ActionType.GET_TOKEN_INFO_SUCCESS:
            return {
                loading: false,
                payload: action.payload
            }
        case ActionType.CLEAR_ALL:
            return {
                loading: false,
                payload: undefined
            }
        default:
            return state
    }
}

export default tokenDetailReducer