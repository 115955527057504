import { createContext, useContext } from 'react'

export interface Asset {
    uuid: string
    symbol: string
    name: string
    asset_type: string
    total_supply: number
    share_price_initial: number
}

export interface Token {
    asset_uuid: string,
    ticker: string,
    total_supply: number,
    pct_tokenized: number,
    proposed_price: number,
    p2p_flag: boolean,
    lp_flag: boolean,
    uuid?: number,
    shares_issued?: number
}

export interface SecurityToken {
    uuid:                   string,
    asset_uuid:             string,
    asset_class:            string,
    asset_name:             string,
    asset_type:             string,
    ticker:                 string,
    date_created:           string,
    total_supply:           string,
    shares_issued:          string,
    pct_tokenized:          string,
    p2p_flag:               boolean,
    lp_flag:                boolean,
    currency_cd:            string,
    seven_day_change:       string,
    capitalization:         string,
    price:                  string,
    proposed_price:         string,
    to_be_listed:           boolean,
}

export interface TokensList {
    tokens: SecurityToken[]
}

export const defaultTokenState: Asset = {
    uuid: "0",
    symbol: "",
    name: "",
    asset_type: "",
    share_price_initial: 0,
    total_supply: 0
}

export const defaultSecurityToken: SecurityToken = {
    uuid:	"0",
    asset_uuid:	"0",
    asset_class:	"",
    asset_name:	"",
    asset_type:	"",
    ticker:	"",
    date_created:	"2022-04-14T00:00:00Z",
    total_supply:	"0",
    shares_issued:	"0",
    pct_tokenized:	"0",
    p2p_flag:	false,
    lp_flag:	false,
    to_be_listed: false,
    currency_cd:	"EUR",
    seven_day_change:	"0",
    capitalization:	"0",
    price:	"0",
    proposed_price: "0",
}

export const defaultTokensList: TokensList = {tokens: []}

//export const SecurityTokenContext = createContext<SecurityToken>(defaultSecurityToken)
export const TokensContext = createContext<TokensList>(defaultTokensList)
//export const usePoolListContext = () => useContext(PoolListContext)