import Navbar from './components/Navbar'
import Footer from './components/Footer'
import { Switch, Route, Redirect, useHistory  } from 'react-router-dom'
import './styles/index.css'
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actionCreators, State, persistor } from './state'
import { AdminContext, defaultAdminState, AdminAccount } from './AdminContext'

import SignInRoute from './routes/SignIn.route'
import HomeRoute from './routes/Home.route'
import AssetDetailRoute from './routes/AssetDetails.route'
import TokenDetailRoute from './routes/TokenDetails.route'
import ListingsRoute from './routes/Listings.route'

import Cookies from 'js-cookie'
import MenuRoute from './routes/Menu.route'
import ProfileRoute from './routes/Profile.route'
import UsersBankRoute from './routes/UsersBank.route'
import UsersListRoute from './routes/UsersList.route'

function App() {
  const dispatch = useDispatch()
  const actions = bindActionCreators(actionCreators, dispatch)

  let history = useHistory();
  const adminLoggingIn = useSelector((state: State) => state.loginAdmin)
  const [admin, setAdmin] = useState<AdminAccount>(defaultAdminState)

  var isLoggedOut: boolean = !adminLoggingIn.loggedIn


  useEffect(() => {   // signout
    if(adminLoggingIn.loggingOut){
      Cookies.remove('session')
      persistor.purge()
      setAdmin(defaultAdminState)
      history.push('/')
    }
  }, [adminLoggingIn.payload])

  useEffect(() => {
    //if (username !== "" && password !== "") {
      if (adminLoggingIn.payload && adminLoggingIn.payload.length > 0) {
          const payloadData = adminLoggingIn.payload[1]
          switch(adminLoggingIn.payload[0]) {
              case(200):
                  const cookieExist = Cookies.get('session')
                  if(cookieExist){

                      let adminData: AdminAccount = {
                          email: payloadData.email,
                          password: payloadData.password,
                          account_permissions: payloadData.account_permissions
                      }
                      setAdmin(adminData)
                  }
                  break
              default:
                  alert(payloadData.error)
                  break
          }
      }
    //}
  }, [adminLoggingIn.payload])

  function handleSignOutAdmin(e: React.MouseEvent) {
    actions.SignOutAdmin()
  }

  function LoggedRoute(props: {path: string, component: any, logged: boolean}): JSX.Element {
    return props.logged ?
      <Redirect from={props.path} to='/' /> :
      <Route path={props.path} component={props.component}/>
  }

  return (
    <><style>
      @import url('https://fonts.googleapis.com/css2?family=Urbanist:wght@300;400;500;700;800&display=swap');
      @import url('https://fonts.googleapis.com/css2?family=Jost:wght@300;400;500;700;800&display=swap');
      @import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@300;400;500;700;800&display=swap');
    </style>
        <AdminContext.Provider value={admin}>


          <div className="min-h-screen content-between ">
            <div className="w-full">
              <Navbar handleSignOutAdmin={handleSignOutAdmin} showAssets={!isLoggedOut}></Navbar>
            </div>
            <Switch className="px-10">

              <Route exact path="/" component={HomeRoute} />
              <Route path={"/account/signin"} component={SignInRoute} />
              <LoggedRoute path={"/menu"} component={MenuRoute} logged={isLoggedOut} />
              <LoggedRoute path={"/profile"} component={ProfileRoute} logged={isLoggedOut} />
              <LoggedRoute path={"/list"} component={ListingsRoute} logged={isLoggedOut}/>
              <LoggedRoute path={"/users"} component={UsersListRoute} logged={isLoggedOut} />

              <LoggedRoute path={"/users-bank"} component={UsersBankRoute} logged={isLoggedOut}/>
              <LoggedRoute path={"/asset/:uuid"} component={AssetDetailRoute} logged={isLoggedOut}/>
              <LoggedRoute path={"/token/:uuid"} component={TokenDetailRoute} logged={isLoggedOut}/>

            </Switch>
            <Footer></Footer>
          </div>

        </AdminContext.Provider>
      </>
  )
}
export default App
