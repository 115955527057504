import { Asset } from "../../AssetContext";
import { ArtInvestingExtraInfo, ArtLendingExtraInfo } from "../../AssetContext";
import { useDispatch } from "react-redux";
import { actionCreators } from "../../state";
import { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import LightTable from "../LightTable";
import TabsMenu from "../TabsMenu";
import SlideShow from "../SlideShow";
import LabelAndDescription from "../LabelAndDescription";
import { list2StringWComma } from "../../utils";
import VideoPlayer from "../VideoPlayer";

export default function ArtTokenBody (props: {assetInfo: Asset, media: any, tokenSymbol: String}): JSX.Element {
    const dispatch = useDispatch()
    const actions = bindActionCreators(actionCreators, dispatch)
    const [documents, setDocuments] = useState([])
    const [images, setImages] = useState<string[]>([])
    const [videos, setVideos] = useState([])

    useEffect(() => {
        if(props.media) {
            actions.getImages(props.media, setImages)
            actions.getDocuments(props.media, setDocuments)
            actions.getVideos(props.media, setVideos)
        }
    }, [props.media])

    function docDownload(documents: any) {
        var tmp = []
        try {
            tmp = documents.map((doc: any, i: number) => 
                {return (
                    <a href={doc} download key={i}>
                        <div className={"border-blue border-solid border-1 rounded uppercase p-2 text-blue font-medium "+
                            "font-code-pro mr-5"}>
                            {"Document "+(i+1).toString()}
                        </div>
                    </a>)})
        } catch (e) {}
        const res = tmp.length > 0 ? <>{tmp}</> : <div className="uppercase text-blue text-urbanist">No documents provided</div>
        return res
    }

    var artInfoArray: ArtLendingExtraInfo[] | ArtInvestingExtraInfo[] = []
    var type = ""
    try {
        type = props.assetInfo.asset_type
        artInfoArray = type === "ART Lending" ? 
            props.assetInfo.extra_info.art_lending.sub_assets : 
            props.assetInfo.extra_info.art_investing.sub_assets
    } catch (e) {
        artInfoArray = []
    }

    const slidesImages = images.length > 0 ? images.map((i, idx) => <img src={i} className="max-w-full max-h-full" key={idx}/>) : []
    const slidesVideos = videos.length > 0 ? videos.map((i, idx) => <VideoPlayer videoSrc={i} width="auto" height="auto" key={idx} />) :[]
    const slides = slidesImages.concat(slidesVideos)

    function showOverview() {
        const labels_1 = ["artist", "Artwork Title", "Signed"]
        const labels_2 = ["Technique", "size", "Year", "location"]
        const descr_1 = [artInfoArray[0].artist, artInfoArray[0].artwork_title, artInfoArray[0].signed]
        const descr_2 =  [artInfoArray[0].technique, artInfoArray[0].size, artInfoArray[0].year, 
            artInfoArray[0].art_location ? list2StringWComma(artInfoArray[0].art_location) : "no information"]
        
        const labels_3 = ["owners"]
        const descr_3 = [artInfoArray[0].owners  ? list2StringWComma(artInfoArray[0].owners, "No information") : "No information"]

        var labels_4: string[] = []
        var descr_4: any[] = []
        var info = []

        switch (type) {
            case "ART Lending":
                info = props.assetInfo.extra_info.art_lending.sub_assets[0]
                labels_4 = ["estimate","insurance", "subtype", "loan amount", "term in months"]
                descr_4 = [info.estimate, info.insurance,  info.subtype,
                    info.loan_amount, info.term_in_months]
                break
            case "ART Investing":
                info = props.assetInfo.extra_info.art_investing.sub_assets[0]
                labels_4 = []
                descr_4 = []
        }
        
        return <>
            <div className="mt-5">
                <LightTable headers={labels_1} rows={[descr_1]} />
                <LightTable headers={labels_2} rows={[descr_2]} />
                <LightTable headers={labels_3} rows={[descr_3]} />
                <LightTable headers={labels_4} rows={[descr_4]} />
            </div>
        </>
    }

    function showMedia() {
        return <>
            <div className="grid lg:grid-cols-2 mt-10">
                <div className="m-5">
                    <SlideShow slides={slides} maxHeightClass="h-96"/>
                </div>
                <div className="grid mt-10">
                    <div className="flex float-left">
                        {docDownload(documents)}
                    </div>
                    <LabelAndDescription label="description" className="mt-5"
                        description={artInfoArray[0] ? artInfoArray[0].description : "no description provided"} />
                </div>
            </div>       
        </>
    }
    
    return <>
        <TabsMenu headers={["Overview", "Media"]}
            contents={[showOverview(), showMedia()]} />
    </>
}