import { Link } from 'react-router-dom'
import { SecurityToken, Token } from '../PoolContext';
import { euroFormat } from "../utils";
import LightTable from "./LightTable";

export default function TokenDescriptionTable(props: {tokensList: {tokens : SecurityToken[]}, assetLinks?: boolean}): JSX.Element {

    const headers = ["CAT", "TYPE", "Ticker", "Name", "Supply", "Price", "Capitalization", "W Var"]

    function tokensList2list(l: any[]) {
        if(l) {
            var tmp = l.map((a) => [a.asset_class, a.asset_type, a.ticker, a.asset_name,
                euroFormat(a.total_supply), euroFormat(a.price !== undefined ? a.price : a.proposed_price), euroFormat(a.capitalization), "%"+euroFormat(a.seven_day_change)])
            return props.assetLinks ? tmp.map((a, i) => a.map((b) => <Link to={"/token/"+l[i].uuid}>{b}</Link>)) : tmp.map((a, i) => a.map((b) => b))
        }
        return []
    }
    
    return <>
        <div className="py-1">
            <LightTable headers={headers} rows={tokensList2list(props.tokensList.tokens)} ></LightTable>
        </div>
    </>
}