//import { defaultAssetInfo, AssetInfo } from "../../PoolContext";
import { ActionType } from "../action-types";
import { Actions } from "../actions";

interface assetInfoState {
    loading: boolean
    payload?: any
}

const defaultAssetInfoState: assetInfoState = {
    loading: false,
    payload: ""
}

const assetInfoReducer = (state: assetInfoState = defaultAssetInfoState, action: Actions): assetInfoState => {
    switch(action.type) {
        case ActionType.GET_ASSET_INFO_REQUEST:
            return {
                loading: true,
            }
        case ActionType.GET_ASSET_INFO_FAILURE:
            return {
                loading: false,
                payload: action.error
            }
        case ActionType.GET_ASSET_INFO_SUCCESS:
            return {
                loading: false,
                payload: action.payload
            }
        case ActionType.CLEAR_ALL:
            return {
                loading: false,
                payload: undefined
            }
        default:
            return state
    }
}

export default assetInfoReducer