import NavbarItem from './NavbarItem'

export default function Footer(): JSX.Element {
    return (
        <div className="mb-0">
            <div className={"text-sm my-2 font-medium grid md:grid-cols-5 grid-cols-1"+
                    " justify-center align-middle text-center "}>
                <div className="md:col-span-2"></div>
                <NavbarItem id="home" to="/" border="none" label="home" className=""/>
                <div className="md:col-span-2"></div>
            </div>
        </div>
    )
}