import { ActionType } from "../action-types"
import { Actions } from "../actions"

interface denyAssetState {
    loading: boolean
    payload?: any
}

export const defaultDenyAssetState: denyAssetState = {
    loading: false
}

const denyAssetReducer = (state: denyAssetState = defaultDenyAssetState, action: Actions): denyAssetState => {
    switch (action.type) {
        case ActionType.DENY_ASSET_REQUEST:
            return {
                loading: true
            }
        case ActionType.DENY_ASSET_FAILURE:
            return {
                loading: false,
                payload: action.error
            }
        case ActionType.DENY_ASSET_SUCCESS:
            return {
                loading: false,
                payload: action.payload
            }
        case ActionType.CLEAR_ALL:
            return {
                loading: false
            }
        case ActionType.CLEAR_ALL_ACTION_PAYLOADS:
                return {
                    loading: false,
                    payload: undefined
                }
        default:
            return state
    }
}

export default denyAssetReducer