import { strLoginInfo, AdminAccount } from './AdminContext'

export default class DexxClient {
    baseUrl: string

    constructor() {
        this.baseUrl = process.env.REACT_APP_ADMIN_BASE_URL ? process.env.REACT_APP_ADMIN_BASE_URL : "";
    }

    async SignInAdminAccount(accessingUser: strLoginInfo) {
        return this._post('/account/signin', {
            "email"       : accessingUser.username,
            "password"    : accessingUser.password,
        })
    }

    async SignOutAdmin() {
        return this._post('/account/signout')
    }

    async GetAdminAccountProfile() {   // get basic account
        return this._get('/account/basic-info')
    }

    async getAllSubmittedAssets() {
        return this._get('/assets/submitted')
    }

    async getAllSubmittedTokens() {
        return this._get('/tokens/submitted')
    }

    async getTokenDetail(uuid: string) {
        return this._get('/token', 'uuid='+uuid)
    }

    async approveAsset(uuid: any) {
        return this._post('/asset/accept', {
            'asset_uuid' : uuid
        })
    }

    async denyAsset(info: any) {
        return this._post('/asset/deny', {
            'asset_uuid' : info.uuid,
            'comment' : info.comment,
        } )
    }

    async rejectAsset(info: any) {
        return this._post('/asset/reject', {
            'asset_uuid' : info.uuid,
            'comment' : info.comment,
        } )
    }

    async approveToken(uuid: any) {
        return this._post('/token/approve', {
            'token_uuid' : uuid,
            'to_be_listed' : true
        } )
    }

    async denyToken(info: any) {
        return this._post('/token/deny', {
            'token_uuid' : info.uuid,
            'comment' : info.comment,
        } )
    }

    async rejectToken(info: any) {
        return this._post('/token/reject', {
            'token_uuid' : info.uuid,
            'comment' : info.comment,
        } )
    }

    async getAssetInfo(uuid: string) {
        return this._get('/asset', 'asset_uuid='+uuid)
    }    
    
    async listAddRemoveRequests() {
        return this._get('/bank/list-requests',)
    }

    async getMediaFile(hashname: string) {
        return this._get("/aws-download", 'filename='+hashname)
    }

    async getAllBankRequests() {
        return this._get("/bank/list-requests")
    }

    async createDepositRequest(request: any) {
        return this._post("/bank/deposit", request)
    }

    async mint(request: any) {
        return this._post("/bank/mint", request)
    }

    async burn(request: any) {
        return this._post("/bank/burn", request)
    }

    async getUsersList() {
        return this._get("/accounts")
    }

    async setInvestorPermission(accountUuid: string) {
        return this._post("/account/investor", {account_uuid: accountUuid})
    }

    async setIssuerPermission(accountUuid: string) {
        return this._post("/account/issuer", {account_uuid: accountUuid})
    }

    async setKYCPermission(accountUuid: string) {
        return this._post("/account/kyc", {account_uuid: accountUuid})
    }

    async _get(path: string, params?: string) {
        let url = this.baseUrl + path;
        if (params !== null) {
            url += '?' + new URLSearchParams(params).toString();
        }
        const response = await fetch(url, {
            mode: 'cors',
            credentials: 'include'
        });
        if (response.status === 200) {
            return [response.status, await response.json()];
        }
        return [response.status, null];
    }

    async _post(path: string, data?: Object) {
        const url = this.baseUrl + path;
        const response = await fetch(url, {
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });

        switch(response.status) {
            case 200:
            case 400:
                return [response.status, await response.json()]
            default:
                return [response.status, null]
        }
    }

    async _put(path: string, data: Object) {
        const url = this.baseUrl + path;
        const response = await fetch(url, {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        if (response.status === 200) {
            return [response.status, await response.json()];
        }
        return [response.status, null];
    }
}

export const client = new DexxClient();