import { createStore, applyMiddleware } from "redux";
import rootReducer from "./reducers";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from 'redux-persist'
//import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import storageSession from 'redux-persist/lib/storage/session'

const persistConfig = {
    key: 'root',
    storage: storageSession,
  }

const persistedReducer = persistReducer(persistConfig, rootReducer)

/*export const store = createStore(
    rootReducer,
    {},
    applyMiddleware(thunk)
)*/

export const store = createStore(
    persistedReducer,
    {},
    applyMiddleware(thunk)
)

export const persistor = persistStore(store)