import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { bindActionCreators } from "redux"
import LabelAndInput from "../components/LabelAndInput"
import { LightButton } from "../components/LightButton"
import LightTable from "../components/LightTable"
import Popup from "../components/Popup"
import { actionCreators } from "../state"
import { timeStampFormat } from "../utils"

type UserDepositRequest = {
    uuid: string,
    account_uuid: string,
    transaction_type: string,
    requested_amount: string,
    unique_code: string,
    requested_at: string,
    status: string,
    user_first_name: string,
    user_last_name: string,
    minted_amount?: string,
    executed_at?: string,
    trx_bank_identifier: string
}

type UserWithdrawRequest = {
    uuid: string,
    account_uuid: string,
    transaction_type: string,
    swift: string,
    iban: string,
    requested_amount: string,
    requested_at: string,
    user_first_name: string,
    user_last_name: string,
    status: string,
    reason: string
}


export default function UsersBankRoute() : JSX.Element {
    const dispatch = useDispatch()
    const actions = bindActionCreators(actionCreators, dispatch)
    
    const [requests, setRequests] = useState<(UserDepositRequest | UserWithdrawRequest)[]>([])
    const [requestsShown, setRequestsShown] = useState<(UserDepositRequest | UserWithdrawRequest)[]>([])
    const [invertAmount, setInvertAmount] = useState<boolean>(false)
    const [invertTimeStamp, setInvertTimeStamp] = useState<boolean>(false)
    const [invertStatus, setInvertStatus] = useState<boolean>(false)
    const btnClass = "rounded m-1"
    const [showExecuted, setShowExecuted] = useState<boolean>(false)

    // deposit
    const [amountD, setAmountD] = useState<string>("0")
    const [bankIdD, setBankIdD] = useState<string>("")
    const [popUpMint, setPopUpMint] = useState<boolean>(false)
    const [popUpMintBody, setPopUpMintBody] = useState<JSX.Element>(<></>)
    const [mintRequest, setMintRequest] = useState<any>([])
    const [sendMint, setSendMint] = useState(false)

    // withdraw
    const [bankIdW, setBankIdW] = useState<string>("")
    const [popUpBurnBody, setPopUpBurnBody] = useState<JSX.Element>(<></>)
    const [popUpBurn, setPopUpBurn] = useState<boolean>(false)
    const [burnRequest, setBurnRequest] = useState<any>({})
    const [sendBurn, setSendBurn] = useState(false)

    // new request
    const [amountN, setAmountN] = useState<string>("0")
    const [uniqueCode, setUniqueCode] = useState<string>("")
    const [transactionBankCode, setTransactionBankCode] = useState<string>("")    
    const [popUpNew, setPopUpNew] = useState<boolean>(false)
    const [popUpNewBody, setPopUpNewBody] = useState<JSX.Element>(<></>)
    const [sendCreateNew, setSendCreateNew] = useState(false)
    const [response, setResponse] = useState(0)

    // Response popup
    const [popUpResponse, setPopUpResponse] = useState<boolean>(false)
    const [responseMsg, setResponseMsg] = useState<string>("")
    const [responseTitle, setResponseTitle] = useState<string>("")

    useEffect(() => {
        actions.getAllBankRequests(setRequests)
    }, [])

    useEffect(() => {
        if(!showExecuted) {
            let tmp = [...requests]
            tmp = tmp.filter((i) => {return i.status !== "Executed"})
            setRequestsShown(tmp)
        } else {
            let tmp = [...requests]
            setRequestsShown(tmp)
        }
    }, [showExecuted, requests])

    function handleMint(uuid : string, amount : string, bank_id : string) {
        setMintRequest([uuid, amount])
        setSendMint(true)
    }
    
    function handleBurn(uuid: string) {
        setBurnRequest(uuid)
        setSendBurn(true)
    }

    function bodyPopUpMint(amount: string, fName: string, lName: string, coin: string, unique: string, 
                            date: string, uuid: string) : JSX.Element { 
        return <><div className="mx-10 mt-10 font-urbanist bg-background grid grid-cols-2">
                <div className="mr-5">
                    <LabelAndInput label={"First Name"} value={fName} fun={undefined} type={"no-input"} color="blue"/>
                    <LabelAndInput label={"Amount"} value={(amountD && amountD === "0") ? amount : amountD} fun={setAmountD} type={"mui-form"} color="blue"/>
                    <LabelAndInput label={"Unique code"} value={unique} fun={undefined} type={"no-input"} color="blue"/>
                </div>
                <div className="ml-5">
                    <LabelAndInput label={"Last Name"} value={lName} fun={undefined} type={"no-input"} color="blue"/>
                    <LabelAndInput label={"Currency"} value={coin} fun={undefined} type={"no-input"} color="blue"/>
                    <LabelAndInput label={"requested at"} value={date} fun={undefined} type={"no-input"} color="blue"/>
                </div>
            </div>            
            <div className="mx-10 mb-5">
                <LabelAndInput label={"Bank identifier"} value={bankIdD} type={"mui-form"} fun={setBankIdD} color="blue"/>
                <LightButton label={"Mint"} onClick={() => {handleMint(uuid, amount, bankIdD), setPopUpMint(false)}} 
                    className="mt-5 bg-blue" alternate={true}/>
                <LightButton label={"Cancel"} onClick={() => {setAmountD("0"), setPopUpMint(false)}} className="mt-5" />
            </div>
        </>
    }
    function bodyPopUpBurn(amount: string, fName: string, lName: string, coin: string, iban: string, swift: string, 
                                uuid: string) : JSX.Element {
        return <><div className="mx-10 mt-10 font-urbanist bg-background grid grid-cols-2">
                <div className="mr-5">
                    <LabelAndInput label={"First name"} value={fName} fun={undefined} type={"no-input"} color="blue"/>
                    <LabelAndInput label={"Amount"} value={amount.toString()} type={"no-input"} fun={undefined} color="blue"/>
                </div>
                <div className="ml-5">
                    <LabelAndInput label={"Last name"} value={lName} type={"no-input"} fun={undefined} color="blue"/>
                    <LabelAndInput label={"Currency"} value={"XEUR"} type={"no-input"} fun={undefined} color="blue"/>    
                </div>
            </div>
            <div className="mx-10 mb-5">
                <LabelAndInput label={"Bank identifier"} value={bankIdW} type={"mui-form"} fun={setBankIdW} color="blue"/>
                <LabelAndInput label={"IBAN"} value={iban} type={"no-input"} fun={undefined} color="blue"/>
                <LabelAndInput label={"Swift"} value={swift} type={"no-input"} fun={undefined} color="blue"/>
                <LightButton label={"Burn"} onClick={() => {handleBurn(uuid), setPopUpBurn(false)}} 
                    className="mt-5 bg-blue" alternate={true}/>
                <LightButton label={"Cancel"} onClick={() => {setPopUpBurn(false)}} className="mt-5" />
            </div>
        </>
    }

    useEffect(() => {
        if(sendCreateNew) {
            setSendCreateNew(false)
            let r = {requested_amount: amountN, bank_unique_code: uniqueCode, trx_bank_identifier: transactionBankCode}
            actions.createDepositRequest(r, setResponse)
            setPopUpNew(false)
        }        
    }, [sendCreateNew])

    useEffect(() => {
        if(sendMint){
            setSendMint(false)
            actions.mint({transaction_uuid: mintRequest[0], minted_amount: amountD.length > 0 ? amountD : mintRequest[1], 
                trx_bank_identifier: bankIdD}, setResponse)
            setBankIdD("")
        }
    }, [sendMint])

    useEffect(() => {
        if(sendBurn){
            setSendBurn(false)
            actions.burn({transaction_uuid: burnRequest, trx_bank_identifier: bankIdW}, setResponse)
            setBankIdW("")
        }
    }, [sendBurn])
    
    useEffect(() => {
        switch(response) {
            case 200:
            case 204:
                setResponseMsg("Success")
                setPopUpResponse(true)
                setResponse(0)
                break
            case 0:
                break
            default:
                setResponseMsg("Error")
                setPopUpResponse(true)
                setResponse(0)
        }
    }, [response])

    function bodyPopUpNew() {
        return <div className="p-10">
            <div className="font-urbanist text-blue mb-5 font-medium text-3xl uppercase">Deposit</div>
            <div>
                <LabelAndInput label={"amount"} value={amountN} fun={setAmountN} type={"mui-form"} color="blue" size="md"/>
                <LabelAndInput label={"unique code"} value={uniqueCode} fun={setUniqueCode} type={"mui-form"} color="blue" size="md"/>
                <LabelAndInput label={"transaction code"} value={transactionBankCode} fun={setTransactionBankCode} type={"mui-form"} color="blue" size="md"/>
                <LightButton label={"confirm"} onClick={() => {setSendCreateNew(true)}} className="rounded-xl mt-5"/>
                <LightButton label={"Cancel"} onClick={() => {setPopUpNew(false)}} className="rounded-xl mt-5" />
            </div>
        </div>
    }

    function isWithdraw(i: any): i is UserWithdrawRequest {
        return i.iban !== undefined
    }

    function sortRows(sortMethod: any, invert: any, setInvert: any) {
        var tmp = [...requestsShown]
        tmp.sort(sortMethod)
        if(invert)
            tmp.reverse()
        setInvert(!invert)
        setRequestsShown(tmp)
    }
    
    return <>
        <Popup show={popUpBurn} title={"burn"} msg={""} btnOk={false} close={() => {setPopUpBurn(false)}} body={popUpBurnBody}></Popup>
        <Popup show={popUpMint} title={"mint"} msg={""} btnOk={false} close={() => {setPopUpMint(false)}} body={popUpMintBody}></Popup>
        <Popup show={popUpNew} title={"create new request"} msg={""} btnOk={false} close={() => {setPopUpNew(false)}} body={popUpNewBody}></Popup>
        <Popup show={popUpResponse} title={responseTitle} msg={responseMsg} btnOk={true} close={() => {setPopUpResponse(false); window.location.reload()}}></Popup>
        
        <div className="bg-background py-10 px-10 grid">
            <div className="font-urbanist text-4xl text-blue float-left uppercase tracking-wider mt-10 mb-20 ml-5">
                DEPOSIT AND WITHDRAW REQUESTS
            </div>
            <div className="mb-5 flex">
                <LightButton label={"create request"} className="rounded px-10" onClick={() => {setPopUpNew(true), setPopUpNewBody(bodyPopUpNew())}}/>
                <LightButton label={showExecuted ? "hide Executed" : "show Executed"} className="ml-5 rounded px-10" 
                    onClick={() => {setShowExecuted(!showExecuted)}}/>
            </div>
            <LightTable
                headers={["User", "type", 
                    {name: "amount", sort: () => {sortRows(((a: any, b: any) => (parseFloat(a.requested_amount) - parseFloat(b.requested_amount))), 
                        invertAmount, setInvertAmount)}}, "coin", 
                    {name: "status", sort: () => {sortRows((a: any, b: any) => (a.status === "Pending" ? 1 : 0) - (b.status === "Pending" ? 1 : 0), 
                        invertStatus, setInvertStatus)}},
                    {name: "timestamp", sort: () => {sortRows((a: any, b: any) => (a.requested_at > b.requested_at ? 1 : -1), invertTimeStamp, setInvertTimeStamp)}},
                    "action"]}
                rows={requestsShown.map((i: any) => {return [i.user_email, i.transaction_type, i.requested_amount, 
                        "XEUR", i.status, timeStampFormat(i.requested_at), (i.status === "Pending" ?
                        ((isWithdraw(i)) ? 
                            <LightButton label={"burn"} className={btnClass} 
                                onClick={() => {setPopUpBurn(true), setPopUpBurnBody(bodyPopUpBurn(i.requested_amount, i.user_first_name, i.user_last_name,
                                    "XEUR", (i.iban ? i.iban : ""), i.swift, i.uuid))}}/> :
                            <LightButton label={"mint"} className={btnClass} 
                                onClick={() => {setAmountD(i.requested_amount), setPopUpMintBody(bodyPopUpMint(i.requested_amount, i.user_first_name, 
                                    i.user_last_name, "XEUR", i.unique_code, timeStampFormat(i.requested_at), i.uuid)), setPopUpMint(true)}}/> 
                            ) : <></>
                )]})}/>
        </div>
    </>
}