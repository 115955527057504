import { Asset } from "../../AssetContext";
import LabelAndDescription from "../LabelAndDescription";
import TabsMenu from "../TabsMenu";

export default function AifTokenBody (props: {assetInfo : Asset}): JSX.Element {
    var asset = {overview: {}, characteristics: {}, investment_limits: {}, irr: {}, fund_terms: {}, other: {}}
    var overviewInfo = {}
    var dealCharInfo = {}
    var invLimitsInfo = {}
    var irrInfo = {}
    var fundTermsInfo = {}
    var otherInfo = {}

    try {
        if(props.assetInfo.asset_type === "Private Debt") {
            asset = props.assetInfo.extra_info.private_debt.sub_assets[0]
        } else {
            asset = props.assetInfo.extra_info.private_equity.sub_assets[0]
        }
    } catch (e) {
        asset = {overview: {}, characteristics: {}, investment_limits: {}, irr: {}, fund_terms: {}, other: {}}
    }
    
    overviewInfo = asset.overview 
    dealCharInfo = asset.characteristics
    invLimitsInfo = asset.investment_limits
    irrInfo = asset.irr
    fundTermsInfo = asset.fund_terms
    otherInfo = asset.other
    function showTable(title: string[], elements: any[]) {
        return title.map((e,i) => {
            return elements[i] ? 
                <div className="grid lg:grid-cols-3 mx-5 my-10" key={i}>
                    <div/>
                    <div>
                        <LabelAndDescription label={e} description={elements[i]} className={" mb-5"} key={i} /> 
                    </div>
                    <div/>
                </div> : <div key={i}></div>
        })
    }
    function showOverview (info: any) {
        if(info) {
            const ovwTitles = ["Investment strategy description", "Target Allocation / Seniority", 
            "Main Risk And Return Characteristics", "Deal Type", "Target Fund Size", "Investment Period",
            "Fund Name"]
            const ovwElems = [info.investment_strategy_description,  info.target_allocation_seniority, 
                info.risk_return_characteristics, info.deal_type, info.target_fund_size, info.investment_period,
                info.fund_name]
            return <>{showTable(ovwTitles, ovwElems)}</>    
        }
        return <></>   
    }

    function showDealChar(info: any) {
        const dealTitles = ["Borrower Profile", "Target Company size", "Loans / Bonds", "Average Rating",
            "Bullet / Amortizing", "Minimum Rating", "Floating / Fixed", "Typical Loan To Value",
            "Primary / Secondary", "Type of Collateral", "Sponsor / Corporate","Max / Average net leverage",
            "Covenant", "Speed of deployment", "Deal Currency", "Target Deal Size", "Fund Currency",
            "Number Of Deals","Max / Typical Term Per Deal","Expected Term Per Deal"]
        const dealElems = [info.borrower_profile, info.target_company_size, info.loans_or_bonds,
            info.average_rating, info.bullet_amortizing, info.minimum_rating, info.floating_or_fixed,
            info.typical_loan_to_value, info.primary_or_secondary, info.type_of_collateral,
            info.sponsor_or_corporate, info.max_average_net_leverage, info.covenant, info.speed_of_deployment,
            info.deal_currency, info.target_deal_size, info.fund_currency, info.number_of_deals,
            info.max_typical_term_per_deal, info.expected_term_per_deal]
        return info  ?
            <>{showTable(dealTitles, dealElems)}</> : <></>
    }

    function showInvLimits (info: any) {
        const invLimitsTitles = ["Geographic Area","Max Sector Limit","Leverage","Max Country Limit",
            "Max Issuer Limit","Derivatives","ESG considerations","Max Issue Excluded"]
        const invLimitsElems = [info.geographic_area,info.max_sector_limit,info.leverage,
            info.max_country_limit,info.max_issuer_limit,info.derivatives,info.esg_considerations,
            info.excluded_profiles]
        return info  ?
            <>
                {showTable(invLimitsTitles, invLimitsElems)}
            </> : <></>
    }
    function showIrr (info: any) {
        const irrTitles = ["Target Gross Irr","Target Cash Yield","Distribution Policy"]
        const irrElems = [info.target_gross_irr,info.target_cash_yield,info.distribution_policy]
        return info  ? <>{showTable(irrTitles, irrElems)}</> : <></>
    }
    function showFundTerms (info: any) {
        const fundTermsTitles = ["First Close","Final Close","Fund Term","Ramp-Up",
            "Investment Period","Capital Calls","Capital Restitutions"]
        const fundTermsElems = [info.first_close,info.final_close,info.fund_term,
            info.ramp_up,info.investment_period,info.capital_calls,info.capital_restitutions]
        return info  ?
            <>
                {showTable(fundTermsTitles, fundTermsElems)}
            </> : <></>
    }
    function showOther (info: any) {
        const otherTitles = ["AIFM","Auditor","Reporting","Distributor","Custiodian","Administrator",
            "Nav. Frequency","Share Classes","Incentive Fees","General Partner","Legal Structure",
            "Management Fees","Sub Distributor","Portfolio Manager","Subsequent Subscriptions"]
        const otherElems = [info.aifm,info.auditor,info.reporting,info.distributor,info.custodian,
            info.administrator,info.nav_frequency,info.share_classes,info.incentive_fees,info.general_partner,
            info.legal_structure,info.management_fees,info.sub_distributor,info.portfolio_manager,
            info.subsequent_subscriptions]
        return info  ?
            <>
                {showTable(otherTitles, otherElems)}
            </> : <></>
    }

    return <>
        <TabsMenu headers={["Overview", "Deal", "investments limits", "Irr / fund terms", "Other"]}
            contents={[showOverview(overviewInfo), showDealChar(dealCharInfo), showInvLimits(invLimitsInfo),
                <>{showIrr(irrInfo)} {showFundTerms(fundTermsInfo)}</>, showOther(otherInfo)]} />
    </>
}