import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { bindActionCreators } from "redux";
import { actionCreators } from "../state"
import { Asset, SecurityToken } from "../PoolContext"
import AssetDescriptionTable from "../components/AssetDescriptionTable";
import TokenDescriptionTable from "../components/TokenDescriptionTable";


export default function ListingsRoute(): JSX.Element {

    const dispatch = useDispatch()
    const actions = bindActionCreators(actionCreators, dispatch)

    const [assetsList, setAssetsList] = useState<Asset[]>([])
    const [tokensList, setTokensList] = useState<SecurityToken[]>([])

    useEffect(() => {
        actions.getAllSubmittedAssets(setAssetsList)
        actions.getAllSubmittedTokens(setTokensList)
    }, [])

    return <>
    <div className="h-full">
        <div className="w-full bg-background px-10 py-5">
            <div className="font-urbanist text-4xl text-blue float-left uppercase tracking-wider mt-10 mb-20 ml-5">Assets</div>
            {assetsList ? <AssetDescriptionTable assetsList={{assets: assetsList}} assetLinks = {true}/> : <></>}
        </div>

        <div className="w-full bg-background px-10 py-5">
            <div className="font-urbanist text-4xl text-blue float-left uppercase tracking-wider mt-10 mb-20 ml-5">Tokens</div>
            {tokensList ? <TokenDescriptionTable tokensList={{tokens: tokensList}} assetLinks = {true}/> : <></>}
        </div>
    </div>
    <div className="h-full"></div>
</>
}
