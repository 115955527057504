import LabelAndDescription from "../LabelAndDescription";
import SlideShow from "../SlideShow";
import { euroFormat } from "../../utils";
import TabsMenu from "../TabsMenu";
import LightTable from "../LightTable";
import { useEffect, useState } from "react";
import VideoPlayer from "../VideoPlayer";
import { useDispatch } from "react-redux";
import { actionCreators } from "../../state";
import { bindActionCreators } from "redux";
import { Asset, HorseExtraInfo, MediaFile } from "../../AssetContext";

export default function HorseTokenBody(props: {assetInfo: Asset, media: MediaFile[] | undefined}): JSX.Element {

    const dispatch = useDispatch()
    const actions = bindActionCreators(actionCreators, dispatch)
    const [documents, setDocuments] = useState([])
    const [images, setImages] = useState<string[]>([])
    const [videos, setVideos] = useState([])
     
    useEffect(() => {
        if(props.media) {
            actions.getImages(props.media, setImages)
            actions.getDocuments(props.media, setDocuments)
            actions.getVideos(props.media, setVideos)
        }
    }, [props.media])
     
    const extra = props.assetInfo.extra_info ? props.assetInfo.extra_info : {}
    var horseEmpty: HorseExtraInfo = {
        age: 0,
        name: "",
        sire: "",
        color: "",
        rider: "",
        gender: "",
        madame: "",
        breeder: "",
        est_value: "",
        discipline: "",
        fei_number: "",
        best_result: "",
        description: "",
        date_of_birth: 0,
        sport_results: [],
        total_winning: "",
        prize_earnings: [],
        rating_scuderia: ""
    }
    const horses = extra.equestrian ? extra.equestrian.sub_assets : [horseEmpty]
    var horseInfo = horses[0]

    const labels_1 = ["Name", "Birthdate", "Years/Months", "Breeder", "Gender"]
    const labels_2 = ["Sire", "Dame", "Total Winnings Euro", "SC1918 Rating", "Discipline"] 
    const labels_3 = ["Est. Value Euro", "Opening_Price", "Minimum_Price", "Maximum_Price",
            "Target Price", "Probability of Sale"]
    
    const descr_1 = horseInfo ? [horseInfo.name, horseInfo.date_of_birth, horseInfo.age, horseInfo.breeder, horseInfo.gender] : []
    const descr_2 = horseInfo ? [horseInfo.sire, horseInfo.madame, euroFormat(horseInfo.total_winning), horseInfo.rating_scuderia, horseInfo.discipline] : []
    const descr_3 = horseInfo ? [euroFormat(horseInfo.est_value), "Unknown", "Unknown", "Unknown", "Unknown", "Unknown",] : []

    const sportResults = horseInfo.sport_results ? horseInfo.sport_results : []
    const prizeEarnings = horseInfo.prize_earnings ? horseInfo.prize_earnings : []
    
    const slidesImages = images.length > 0 ? images.map((i, idx) => <img src={i} className="max-w-full max-h-full" key={idx}/>) : []
    const slidesVideos = videos.length > 0 ? videos.map((i, idx) => <VideoPlayer videoSrc={i} width="auto" height="auto" key={idx} />) :[]
    const slides = slidesImages.concat(slidesVideos)

    function docDownload(documents: any) {
        var tmp = []
        try {
            tmp = documents.map((doc: any, i: number) => 
                {return (
                    <a href={doc} download key={i}>
                        <div className={"border-blue border-solid border-1 rounded uppercase p-2 text-blue font-medium "+
                            "font-code-pro mr-5"}>
                            {"Document "+(i+1).toString()}
                        </div>
                    </a>)})
        } catch (e) {}
        const res = tmp.length > 0 ? <>{tmp}</> : <div className="uppercase text-blue text-urbanist">No documents provided</div>
        return res
    }
    
    function showOverview() {
        return <div className="grid">
            <div className="mt-10 mx-5">
                <LightTable headers={labels_1} rows={[descr_1]} />
                <LightTable headers={labels_2} rows={[descr_2]} />
                <LightTable headers={labels_3} rows={[descr_3]} />
            </div>
        </div>
    }

    function showSportOverview() {
        const rows = sportResults.map((i: any) => {return [i.rank, i.year, i.competition, i.height, i.rider]})
        const res = prizeEarnings.map((p : {year: string, prize: string}) => {
            return <>
                <div className="font-urbanist uppercase font-semibold text-2xl text-blue my-10">
                    {p.year + ": "+euroFormat(p.prize)+"€"}</div>
                <LightTable headers={["Rank", "Year", "Competition", "Height", "Rider"]} 
                    rows={rows.filter((sp: any[]) => sp[1].toString() === p.year)} />
            </>
        })
        return res.reverse()
    }

    function showMedia() {
        return <>
            <div className="grid lg:grid-cols-2 mt-10">
                <div className="m-5">
                    <SlideShow slides={slides} maxHeightClass="h-96"/>
                </div>
                <div className="grid mt-10">
                    <div className="flex float-left">
                        {docDownload(documents)}
                    </div>
                    <LabelAndDescription label="description" className="mt-5"
                        description={horseInfo ? horseInfo.description : "no description provided"} />
                </div>
            </div>       
        </>
    }
    
    return <>
        <TabsMenu headers={["Overview", "Media", "Sport overview and results"]}
            contents={[showOverview(), showMedia(), showSportOverview()]} />
    </>
}