import { ActionType } from "../action-types"
import { Actions } from "../actions"

interface approveTokenState {
    loading: boolean
    payload?: any
}

export const defaultApproveTokenState: approveTokenState = {
    loading: false
}

const approveTokenReducer = (state: approveTokenState = defaultApproveTokenState, action: Actions): approveTokenState => {
    switch (action.type) {
        case ActionType.APPROVE_TOKEN_REQUEST:
            return {
                loading: true
            }
        case ActionType.APPROVE_TOKEN_FAILURE:
            return {
                loading: false,
                payload: action.error
            }
        case ActionType.APPROVE_TOKEN_SUCCESS:
            return {
                loading: false,
                payload: action.payload
            }
        case ActionType.CLEAR_ALL:
            return {
                loading: false
            }
        case ActionType.CLEAR_ALL_ACTION_PAYLOADS:
            return {
                loading: false,
                payload: undefined
            }
        default:
            return state
    }
}

export default approveTokenReducer