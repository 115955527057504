import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { bindActionCreators } from "redux";
import { SecurityToken, defaultSecurityToken } from "../PoolContext";
import { actionCreators, State } from "../state";
import LabelAndInput from "../components/LabelAndInput";
import { LightButton } from "../components/LightButton";
import TokenDescriptionTable from "../components/TokenDescriptionTable";
import LightTable from "../components/LightTable";
import { useHistory } from 'react-router-dom'


export default function TokenDetailRoute(props: any): JSX.Element {
    const uuid = props.match.params.uuid
    const dispatch = useDispatch()
    const actions = bindActionCreators(actionCreators, dispatch)

    const tokenDetailState = useSelector((state: State) => state.tokenDetail)
    const [tokenInfo, setTokenInfo] = useState<SecurityToken>(defaultSecurityToken)
    const [comment, setComment] = useState("")
    let history = useHistory()


    useEffect(() => {
        actions.getTokenDetail(uuid)
        //actions.getAssetInfo(uuid, undefined)
        //actions.getAssetMedia(uuid)
    }, [])

    useEffect(() => {
        if (tokenDetailState.payload) {
            setTokenInfo(tokenDetailState.payload.token)
        }
      }, [tokenDetailState.payload])

    if(!tokenInfo.uuid || tokenInfo.uuid==="0") {
        <div className="grid justify-center text-white text-4xl font-urbanist">Token not found</div>
    }

    function handleAcceptToken(){
        actions.approveToken(uuid)
        history.push("/list")
    }

    function handleDenyToken(){
        if(comment === ""){
            alert('Please explain why you are denying this token')
        } else {
            actions.denyToken({'uuid':uuid, 'comment': comment})
            history.push("/list")
        }
    }

    function handleRejectToken(){
        if(comment === ""){
            alert('Please explain why you are rejecting this token')
        } else {
            actions.rejectToken({'uuid':uuid, 'comment': comment})
            history.push("/list")
        }
    }
    return (
        <>
            <div className="grid bg-background px-10 py-10">

                <div className="grid pt-5 pb-5 items-center pl-2 pr-2 lg:mx-32">

                    <div className="font-urbanist text-4xl text-blue float-left uppercase tracking-wider">
                        Token to approve:
                    </div>
                    <div className="mt-10">
                        <TokenDescriptionTable tokensList={{tokens: [tokenInfo]}} />
                        <LightTable headers={["Creation date", "% tokenized","currency", "Listed"]} 
                            rows={[[tokenInfo.date_created.substring(0,10), tokenInfo.pct_tokenized, tokenInfo.currency_cd,
                                tokenInfo.to_be_listed ? "yes" : "no"]]} />
                    </div>
                    <div className="grid md:grid-cols-3 mt-10">
                        <LightButton label={"Approve"} className="rounded-xl font-bold px-2 text-xl mx-10 md:my-0 my-2"
                            onClick={handleAcceptToken} />
                        <LightButton label={"Deny"} className="rounded-xl font-bold px-2 text-xl mx-10 md:my-0 my-2"
                            onClick={handleDenyToken} />
                        <LightButton label={"Reject"} className="rounded-xl font-bold px-2 text-xl mx-10 md:my-0 my-2"
                            onClick={handleRejectToken} />
                    </div>
                </div>
                <div className="w-full px-10 lg:px-32">
                    <div className="grid md:grid-cols-3">
                        <LabelAndInput label={"If not approved, explain the reason"} value={comment} fun={setComment}
                            type="textarea" rows={3} className="md:col-span-2 font-semibold" required={true} color="blue"/>
                    </div>
                </div>
            </div>
            
        </>)
}