import { combineReducers } from "redux";
import loginReducer from "./loginReducer";
import assetsReducer from "./assetsReducer";
import tokensReducer from "./tokensReducer";
import tokenDetailReducer from "./tokenDetailReducer";
import approveAssetReducer from "./approveAssetReducer";
import denyAssetReducer from "./denyAssetReducer";
import rejectAssetReducer from "./rejectAssetReducer";
import approveTokenReducer from "./approveTokenReducer";
import denyTokenReducer from "./denyTokenReducer";
import rejectTokenReducer from "./rejectTokenReducer";
import assetInfoReducer from "./assetInfoReducer";

const rootReducer = combineReducers({
    loginAdmin: loginReducer,
    assetList : assetsReducer,
    tokenList : tokensReducer,
    assetInfo : assetInfoReducer,
    tokenDetail : tokenDetailReducer,
    approveAsset: approveAssetReducer,
    denyAsset: denyAssetReducer,
    rejectAsset: rejectAssetReducer,
    approveToken: approveTokenReducer,
    denyToken: denyTokenReducer,
    rejectToken: rejectTokenReducer,
})

export default rootReducer
export type State = ReturnType<typeof rootReducer>