export const makeCurrency = (n: number) => n.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD'
})

export function euroFormat(num: any ): string {
    if (typeof num == "string") {
        return parseFloat(num).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    }
    if (typeof num == "number") {
        return num.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    }
    return ""
}

export function list2StringWComma(l: string[], replaceIfEmpty?: string) {
    if(l.length < 1 && replaceIfEmpty) 
        return replaceIfEmpty
    return l.map((i: string, idx: number) => {return idx === l.length-1 ? i : i+", "})
}

export function timeStampFormat(date: string) {
    return date.substring(0,16).replace("T", " ")
}