import { useState } from "react"
import NavbarItem from "./NavbarItem"

export default function DropDownMenu(props: {username: string, logged: boolean, signOut: any}): JSX.Element {
    const [isOpen, setIsOpen] = useState(false)
    return <>
        <div className="grid lg:hidden">
            <div className="text-white font-urbanist cursor-pointer "
                onClick={ () => setIsOpen(!isOpen)}>
                <span id="menu-icon" className="material-icons text-white text-4xl mt-4 justify-center flex mr-4">
                    {isOpen ? "x" : "="}
                    
                </span>
            </div>
            
            <div className={isOpen ? "grid text-md w-full align-middle mt-4" : "hidden"}>
                    {props.logged ? <>
                    <NavbarItem id="menu" to="/menu" label="menu" border="left" />
                    <NavbarItem id="user" to="/profile" label={props.username}  border="left"/>
                    <NavbarItem id="users" to="/users" label="users"  border="left"/>
                    <NavbarItem id="logout" to="/" label="log out" border="left" onClick={props.signOut}/>
                    </> : <>
                    <NavbarItem to="/account/signin" border="left" label="Log in" id="login" />
                    </>}
            </div>
        </div>
    </>
}