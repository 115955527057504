import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { LightButton } from "../components/LightButton";
import LightTable from "../components/LightTable";
import Popup from "../components/Popup";
import { actionCreators } from "../state";

export default function UsersListRoute() : JSX.Element {
    
    interface UserInfo {
        uuid: string
        email: string
        is_investor: boolean
        is_issuer: boolean
        kyc_submitted: boolean
    }
    const dispatch = useDispatch()
    const actions = bindActionCreators(actionCreators, dispatch)
    const [usersInfo, setUsersInfo] = useState<UserInfo[]>([])
    const [response, setResponse] = useState<any>(false)
    const [showPopup, setShowPopup] = useState<boolean>(false)
    const [messagePopup, setMessagePopup] = useState<string>("")
    
    useEffect(() => {
        actions.getUsersList(setUsersInfo)
    }, [])
    
    return <>
        <Popup show={showPopup} title={""} msg={""} btnOk={true} close={() => {setShowPopup(false); window.location.reload()}} body={<div className="mx-10 text-blue">{messagePopup}</div>}/>
        <div className="bg-background p-10">
            <div className="font-urbanist text-4xl text-blue float-left uppercase tracking-wider mt-10 mb-20 ml-5">
                Users
            </div>
            <LightTable headers={["email", "kyc submitted", "investor", "issuer",]} rows={
                usersInfo.map((u) => {return [
                    u.email, 
                    u.kyc_submitted ? "True" : <LightButton className="w-fit mx-5 rounded-md" fontClasses="text-sm" label={"Grant"} 
                        onClick={() => { setShowPopup(true); actions.setKYCPermission(u.uuid, setResponse); setMessagePopup(response);}}/>,
                    u.is_investor ? "True" : <LightButton className="w-fit mx-5 rounded-md" fontClasses="text-sm" label={"Grant"} 
                        onClick={() => { setShowPopup(true); actions.setInvestorPermission(u.uuid, setResponse); setMessagePopup(response);}}/>,
                    u.is_issuer ? "True" : <LightButton className="w-fit mx-5 rounded-md" fontClasses="text-sm" label={"Grant"} 
                        onClick={() => { setShowPopup(true); actions.setIssuerPermission(u.uuid, setResponse); setMessagePopup(response)}}/>,
                ]})
                } />
        </div>
        
    </>
}