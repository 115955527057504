import { maxHeight } from "@mui/system"
import { useState } from "react"

export default function SlideShow(props : {slides : JSX.Element[], maxHeightClass: string}) : JSX.Element {
    const [counter, setCounter] = useState(0)

    function incrementCounter() {
        counter >= props.slides.length -1 ? setCounter(0) : setCounter(counter+1)
    }
    function decrementCounter() {
        counter <= 0 ? setCounter(props.slides.length -1) : setCounter(counter-1)
    }
    const [animateL, setAnimateL] = useState(false)
    const [animateR, setAnimateR] = useState(false)
    
    return <>
        {(props.slides && props.slides.length > 0) ?
        <div className="grid items-center align-middle justify-items-center min-h-3/4">
            <div className={props.maxHeightClass}>
                {props.slides[counter]}
            </div>
            <div className="grid grid-cols-3 items-center mt-2">
                <button onClick={(() => {decrementCounter()})} 
                        onMouseEnter={() => {setAnimateL(true)}}
                        onMouseLeave={() => {setAnimateL(false)}}
                        className={"text-blue bg-transparent border-0 text-4xl active:animate-ping "+
                                "cursor-pointer "+ (animateL ? "animate-arrow_moderate" : "")}>{"<"}</button>
                <div className="flex">    
                    {props.slides.map((_, i) => <div className={" rounded-xl m-1 h-5 w-5 " + (counter === i ? "bg-primary" : "bg-light_blue")} key={i}></div>)}
                </div>
                <button onClick={(() => {incrementCounter()})}
                        onMouseEnter={() => {setAnimateR(true)}}
                        onMouseLeave={() => {setAnimateR(false)}}
                        className={"text-blue bg-transparent border-0 text-4xl active:animate-ping content-center "+
                                "cursor-pointer "+(animateR ? "animate-arrow_moderate" : "")}>{">"}</button>
            </div>
        </div> :
        <></>}
        
    </>
}