import { ActionType } from "../action-types"
import { Actions } from "../actions"

interface denyTokenState {
    loading: boolean
    payload?: any
}

export const defaultDenyTokenState: denyTokenState = {
    loading: false
}

const denyTokenReducer = (state: denyTokenState = defaultDenyTokenState, action: Actions): denyTokenState => {
    switch (action.type) {
        case ActionType.DENY_TOKEN_REQUEST:
            return {
                loading: true
            }
        case ActionType.DENY_TOKEN_FAILURE:
            return {
                loading: false,
                payload: action.error
            }
        case ActionType.DENY_TOKEN_SUCCESS:
            return {
                loading: false,
                payload: action.payload
            }
        case ActionType.CLEAR_ALL:
            return {
                loading: false
            }
        case ActionType.CLEAR_ALL_ACTION_PAYLOADS:
                return {
                    loading: false,
                    payload: undefined
                }
        default:
            return state
    }
}

export default denyTokenReducer