import { ActionType } from '../action-types'
import { Actions } from '../actions'
import { TokensList } from '../../PoolContext'

interface tokenState {
    loading: boolean
    payload?: TokensList
}

const defaultTokensState: tokenState = {
    loading: false,
    payload: {tokens: []}
}

const tokensReducer = (state: tokenState = defaultTokensState, action: Actions): tokenState => {
    switch(action.type) {
        case ActionType.GET_ALL_SUBMITTED_TOKENS_REQUEST:
            return {
                loading: true
            }
        case ActionType.GET_ALL_SUBMITTED_TOKENS_FAILURE:
            return {
                loading: false,
                payload: action.error
            }
        case ActionType.GET_ALL_SUBMITTED_TOKENS_SUCCESS:
            return {
                loading: false,
                payload: action.payload
            }
        case ActionType.CLEAR_ALL:
            return {
                loading: false,
                payload: undefined
            }
        default:
            return state
    }
}

export default tokensReducer