import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { State } from "../state"
import { defaultAdminState, AdminAccount } from '../AdminContext'
import { useHistory  } from 'react-router-dom'
import { LightButton } from "../components/LightButton"

export default function MenuRoute(): JSX.Element {
    
    const adminLoggingIn = useSelector((state: State) => state.loginAdmin)
    const [admin, setAdmin] = useState<AdminAccount>(defaultAdminState)
    let history = useHistory()  
    
    //let profileUrl: string = ""
    //if(adminLoggingIn.payload === undefined || adminLoggingIn.payload[1].email === undefined || adminLoggingIn.payload[1].email === "") profileUrl = "/account/signin"
    //else profileUrl = "/profile"

    useEffect(() => {   
        if (adminLoggingIn.payload && adminLoggingIn.payload.length > 0) {
            const payloadData = adminLoggingIn.payload[1]                             
            let adminData = {
                email: payloadData.email,
                password: payloadData.password,
                account_permissions: payloadData.account_permissions
            }
            setAdmin(adminData)          
        }
    }, [])
    
    return <>
        <div className="row">
            <div className="col-5 md:text-6xl text-4xl text-white">
            
            </div>
        </div>

        <div className="grid md:grid-cols-2 ">
            <div className="grid justify-center justify-items-center items-center">
                <div className="font-thin text-white font-jost uppercase md:text-5xl text-4xl tracking-wide md:pl-20 pl-2">
                    <div className="md:text-6xl text-5xl">CIAO</div>
                    <br />{admin.email}   
                </div>
            </div>
            
            <div className="grid md:px-20 px-5 mt-10" >
                <div className="font-urbanist py-2 tracking-wider right-text">
                        <div  className="grid justify-items-center">                       
                                
                            <LightButton onClick={() => {history.push("/admin/drafts")}} className="rounded-xl px-10 my-2"
                                    label={"Assets Created"} alternate={true}/>                           
                            <LightButton onClick={() => {history.push("/admin/approvals")}} className="rounded-xl px-10 my-2"
                                    label={"Assets Approvals"} alternate={true}/>                                    
                            <LightButton onClick={() => {history.push("/admin/approved")}} className="rounded-xl px-10 my-2"
                                    label={"Assets Approved"} alternate={true}/>      
                            <LightButton onClick={() => {history.push("/admin/tokenizing")}} className="rounded-xl px-10 my-2"
                                    label={"Tokenizations Approvals"} alternate={true}/>
                            <LightButton onClick={() => {history.push("/admin/tokenized")}} className="rounded-xl px-10 my-2"
                                    label={"Assets Tokenized"} alternate={true}/>
                            
                        </div>
                </div>
            </div>
        </div>   
    </>
}
